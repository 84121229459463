/*compontents/Sidebar/syles.scss*/

h4.stage-title {
  margin: 0;
  min-height: 17.868%;
  font-family: "Original-Quality-Demo";
  color: #FFF;
  font-size: 300%;
  text-shadow: -3px -3px 0 #7C654D,
    3px -3px 0 #7C654D,
    -3px 3px 0 #7C654D,
    3px 3px 0 #7C654D;

}

.page-title {
  position: fixed; // Posiciona fixo na tela
  top: 53.8%; // Centraliza verticalmente em 53.8% do topo da tela
  left: 50%; // Centraliza horizontalmente
  transform: translate(-50%, -50%); // Ajuste fino para centralizar perfeitamente
  width: auto; // A largura será determinada pelo conteúdo da imagem
  height: auto; // A altura será determinada pelo conteúdo da imagem
  z-index: 100; // Garante que o título esteja sobre outros elementos

  img {
    width: 750px; // Ajusta a largura da imagem para preencher o contêiner
    height: auto; // Mantém a proporção da imagem
  }
}

div.book {
  display: flex;
  justify-content: center;
  font-family: "Acme-Regular";
  color: #FFF;
  font-size: 210%;
  text-decoration: none;
  transition: box-shadow 0.3s ease;
  position: absolute;

  >p {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }

  &:hover {
    filter: brightness(1.1); // Adiciona um brilho à imagem quando o mouse passa sobre ela
  }

}