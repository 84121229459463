/* App.Css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #00000000;

  background-image:
    url("./assets/images/background1.png"),
    url("./assets/images/background2.png"),
    url("./assets/images/background3.png"),
    url("./assets/mobile/mobileBackground1.svg"),
    url("./assets/mobile/mobileBackground2.svg"),
    url("./assets/mobile/mobileBackground3.svg"),
    url("./assets/mobile/mobileHBackground1.svg"),
    url("./assets/mobile/mobileHBackground2.svg"),
    url("./assets/mobile/mobileHBackground3.svg");
  background-size: 0px; /* Não mostra, apenas pré-carrega */
}

/* Usado quando específico stage é selecionado */
.app.background1 {
  background: url("./assets/images/background1.png") no-repeat center center;
  background-size: cover;
}
.app.background2 {
  background: url("./assets/images/background2.png") no-repeat center center;
  background-size: cover;
}
.app.background3 {
  background: url("./assets/images/background3.png") no-repeat center center;
  background-size: cover;
}

@media (max-height: 450px) {
  .app {
    width: 100vw;
    height: 100vh;
 }

 .app.background1 {
     background: url("./assets/mobile/mobileHBackground1.svg") no-repeat center center;
     background-size: cover;
 }

 .app.background2 {
     background: url("./assets/mobile/mobileHBackground2.svg") no-repeat center center;
     background-size: cover;
 }

 .app.background3 {
     background: url("./assets/mobile/mobileHBackground3.svg") no-repeat center center;
     background-size: cover;
 }
}

@media (max-width: 768px) {
  .app {
     width: 100vw;
     height: 100vh;
  }

  .app.background1 {
      background: url("./assets/mobile/mobileBackground1.svg") no-repeat center center;
      background-size: cover;
  }

  .app.background2 {
      background: url("./assets/mobile/mobileBackground2.svg") no-repeat center center;
      background-size: cover;
  }

  .app.background3 {
      background: url("./assets/mobile/mobileBackground3.svg") no-repeat center center;
      background-size: cover;
  }
  
}