// components/Modal/style.scss

.emptyTitle {
  height: 40px; // altura do h4 original
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
    border: none;
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 68vw; // Largura do modal
    height: 43.2vw;    // Altura do modal
    margin: auto;
    z-index: 10001;
    background: white;      // Fundo do modal
    border: 6px solid white; // Bordas brancas
    border-radius: 10px;    // Bordas arredondadas
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); // Sombra
    overflow: hidden; // Evita desbordamento de conteúdo
  }
  
  .modalHeader h4 {
    margin: 0;
    min-height: 17.868%;
    font-family: "Original-Quality-Demo";
    color: #141414;
    font-size: 300%;
    border: none;
  }
  
  .modalContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
  }
  
  .modalHeader {
    margin-bottom: 0.1vw;
    text-align: center;
    position: relative;
    border: none;
  }
  
  .closeButton {
    position: absolute;
    top:0;
    right: 0.9vw;
    font-family: "Original-Quality-Demo";
    font-size: 1.8vw;
    font-weight: bold;
    color: red;
    cursor: pointer;
    border: none;
  }
  
  .modalIframe {
    width: 100%;
    height: 89.1%;
    border: none;  // Remove qualquer borda padrão
  }

  @media (max-height: 450px) {
    .emptyTitle {
      height: 21px; // altura do h4 original
    }

    .modalContent {
      width: 55vw; /* Largura do modal ajustada */
      height: 35vw; /* Altura do modal ajustada */
      margin: 0 10vw; /* Centraliza o modal horizontalmente */
  }

  .modalIframe {
      width: 100%;
      height: 90%;
      border: none; /* Remove qualquer borda padrão */
  }

  .closeButton {
      top: -0.3vw;
      right: 1vw;
      font-size: 2.5vw;
  }
}

  @media (max-width: 768px) {
    .emptyTitle {
      height: 21px; // altura do h4 original
    }

    .modalContent {
      width: 90vw; // Largura do modal
      height: 61vw; // Altura do modal
    }

    .modalIframe {
      width: 100%;
      height: 85%;
      border: none;  // Remove qualquer borda padrão
    }

    .closeButton {
      position: absolute;
      top:0.1vw;
      right: 0.9vw;
      font-family: "Original-Quality-Demo";
      font-size: 4vw;
      font-weight: bold;
      color: red;
      cursor: pointer;
      border: none;
    }
  }