.thumbEmBreve {
  cursor: auto;
  pointer-events: none;
  filter: grayscale(100%);
}

.thumbnails {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    position: absolute; // Mantém todas as imagens na mesma posição base, sobrepostas
    transition: transform 0.3s ease-in-out; // Suaviza a transição do efeito de escala
    width: 354px; // Define uma largura padrão
    height: auto; // Mantém a proporção das imagens

    &:first-child {
      z-index: 2;
      transform: translate(120px, 390px) scale(1); // Move a primeira imagem para baixo e para a esquerda
    }

    &:nth-child(2) {
      z-index: 1;
      transform: translate(-50px, 200px) scale(1); // Move a segunda imagem um pouco para cima e mais para a esquerda
    }

    &:hover {
      filter: brightness(1.1);
    }
    
    &:first-child:hover {
      transform: translate(120px, 390px) scale(1.1);
    }

    &:nth-child(2):hover {
      z-index: 3;
      transform: translate(-50px, 200px) scale(1.1);
    }
  }
}
