.topbar1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgba(192, 159, 244);
  backdrop-filter: blur(10px); /* Efeito de desfoque para o vidro */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Aumentando o z-index para garantir que a barra superior esteja na frente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.233); /* Adicionando a sombra na parte inferior */
}

.topbar2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgba(26, 194, 194);
  backdrop-filter: blur(10px); /* Efeito de desfoque para o vidro */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Aumentando o z-index para garantir que a barra superior esteja na frente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.233); /* Adicionando a sombra na parte inferior */
}

.topbar3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgb(243, 159, 69);
  backdrop-filter: blur(10px); /* Efeito de desfoque para o vidro */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Aumentando o z-index para garantir que a barra superior esteja na frente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.233); /* Adicionando a sombra na parte inferior */
}

.logo {
  position: fixed;
  top: 3px; /* Corrigindo a unidade para pixels */
  right: 15px; /* Mudando de left para right */
  width: auto; /* Ajustando a largura para se adaptar ao conteúdo */
  height: auto;
  display: flex;
  align-items: center; /* Alinhando verticalmente ao centro */
  justify-content: flex-end; /* Alinhando horizontalmente à direita */
  z-index: 2000; /* Mantendo o z-index alto para garantir que o logotipo esteja na frente */

  img {
    width: 120px; /* Ajuste conforme necessário */
    height: auto;
  }
}

.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px; /* Mudança para o lado esquerdo*/
  cursor: pointer;
  font-size: 24px;
  z-index: 2001; /* Aumentando o z-index para garantir que o ícone do menu esteja na frente */
  
  img {
    width: 40px; /* Ajuste conforme necessário */
    height: auto;
  }
}

.mobile-sidebar {
  position: fixed;
  width: 140px;
  height: auto;
  background-color: #BAA485; /* Cor de fundo do menu */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra para dar um efeito de flutuação */
  left: -250px;
  transition: left 0.3s;
  top: 60px; /* Ajuste conforme necessário para deixar um espaço abaixo do ícone do menu */
  z-index: 3000; /* Aumentando o z-index para garantir que o menu esteja na frente */
  padding: 10px; /* Espaçamento interno */

  &.open {
    left: 20px; /* Mudança para o lado esquerdo */
  }
}

.menu-section {
  margin-bottom: 20px; /* Espaçamento entre seções */
  text-align: center; /* Centraliza o texto dentro da seção */
}

.menu-title {
  width: 100%; /* Ajusta a largura para 100% do contêiner */
  height: auto;
  margin-bottom: 10px; /* Espaçamento entre o título do menu e as entradas dos anos */
  margin-top: 10px; /* Ajuste conforme necessário */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}

.year-entry {
  cursor: pointer;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin-bottom: 1px; /* Espaçamento entre os itens */
  opacity: 0.5; /* Imagens opacas por padrão */
  
  &.selected {
    opacity: 1; /* Imagem em cor normal quando selecionada */
  }

  &:hover {
    opacity: 1; /* Imagem em cor normal ao passar o mouse */
  }

  img {
    width: 100px; /* Ajuste conforme necessário */
    height: auto;
  }
}

@media (max-height: 450px) {

  .topbar1 {
    width: 100%;
    height: 55px;
  }

  .topbar2 {
    width: 100%;
    height: 55px;
  }

  .topbar3 {
    width: 100%;
    height: 55px;
  }

  .logo {
    img {
      width: 93px; /* Ajuste conforme necessário */
      height: auto;
    }
  }
  
  .hamburger-menu {
    top: 8px;
  }

  .page-title {
    position: fixed; /* Posiciona fixo na tela */
    top: 31%; /* Centraliza verticalmente em 30% do topo da tela */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, -50%); /* Ajuste fino para centralizar perfeitamente */
    width: auto; /* A largura será determinada pelo conteúdo da imagem */
    height: auto; /* A altura será determinada pelo conteúdo da imagem */
    z-index: 100; /* Garante que o título esteja atrás do menu */
  
    img {
      width: 70vw; /* Ajusta a largura da imagem para preencher o contêiner */
      height: auto; /* Mantém a proporção da imagem */
    }
  }

  .thumbEmBreve {
    cursor: auto;
    pointer-events: none;
    filter: grayscale(100%);
  }

  .thumbnails {
    flex-direction: row; /* Alinha as miniaturas lado a lado */
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centraliza horizontalmente */
    display: flex;
    justify-content: center; /* Centraliza as miniaturas dentro do contêiner */
  }

  .thumbnails img {
    width: 35vw; /* Ajuste conforme necessário */
    height: auto;
    margin: 0 10px; /* Espaço entre as miniaturas */
    position: relative;
    transform: none !important;
  }

  .mobile-sidebar {
    overflow-y: auto; /* Habilita a rolagem vertical */
    max-height: 75vh; /* Limita a altura máxima para permitir a rolagem */
  }
}

@media (max-width: 768px) {
  .page-title {
    position: fixed; /* Posiciona fixo na tela */
    top: 20%; /* Centraliza verticalmente em 20% do topo da tela */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, -50%); /* Ajuste fino para centralizar perfeitamente */
    width: auto; /* A largura será determinada pelo conteúdo da imagem */
    height: auto; /* A altura será determinada pelo conteúdo da imagem */
    z-index: 100; /* Garante que o título esteja atrás do menu */
  
    img {
      width: 100vw; /* Ajusta a largura da imagem para preencher o contêiner */
      height: auto; /* Mantém a proporção da imagem */
    }
  }

  .thumbEmBreve {
    cursor: auto;
    pointer-events: none;
    filter: grayscale(100%);
  }

  .thumbnails {
    flex-direction: column; /* Alinha as miniaturas lado a lado */
    top: 60vh;
    left: 50%;
    transform: translate(-50%, -50%); /* Centraliza horizontalmente */
    display: flex;
    justify-content: center; /* Centraliza as miniaturas dentro do contêiner */
  }

  .thumbnails img {
    width: 75vw; /* Ajuste conforme necessário */
    height: auto;
    margin: 20px 0; /* Espaço entre as miniaturas */
    position: relative;
    transform: none !important;
  }
}